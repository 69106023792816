<template>
    <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        专区列表
      </template>
      <template #input>
        <a-button
          class="all_boder_btn"
          @click="toDetail(1)"
        >新建</a-button>
      </template>
    </HeaderBox>
    <div class="table-box">
      <a-tabs
        :default-active-key="type"
        @change="callback"
      >
        <a-tab-pane v-for="item in classList"
          :key="item.autismClassificationId"
          :tab="item.name"
        ></a-tab-pane>
      </a-tabs>
    </div>

    <div class="introduce-box">
      <!-- <div class="introduce-title">自闭症之声介绍</div> -->
      <div class="introduce-content">
        <div class="introduce-left">
          <a-textarea
            placeholder="请设置新知共享介绍"
            :rows="4"
            style="resize:none;"
            disabled="disabled"
            v-model="info"
          />
        </div>
        <div class="introduce-right">
          <a-button
            class="all_boder_btn"
            @click="editFilling"
          >编辑</a-button>
        </div>
      </div>

    </div>

    <a-table
      class="table-template"
      :rowKey="(item) => item.autismId"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      @change="onPage"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
    >
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>

      <template slot="createTime" slot-scope="text">
        {{ createtime(text) }}
      </template>
      
      <!-- 状态 -->
      <template slot="state" slot-scope="text">
        <span class="state_all" :class="text==1 ? 'state_color_red':'state_color_green'">{{ text ? '下线' : '上线' }}</span>
      </template>

      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span @click="toDetail(2,row.autismId)">编辑</span> |
          <span @click="onDelete(row.autismId)">删除</span> |
          <span @click="onLine(row, 0)">{{ row.state ? '上线' : '下线' }}</span>
        </span>
      </template>

      <template  slot="isShow" slot-scope="item,row">
        <a-checkbox :checked="row.type == 0" @click="onLine(row, 1)" />
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width='530px'
      title="编辑新知共享介绍"
      :centered='true'
      :closable='false'
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <div class="right_Div">
            <a-textarea
              placeholder="新知共享介绍"
              :rows="4"
              style="resize:none;"
              v-model="formInfo"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="editOk">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "书名",
    align: "center",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "state",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
  {
    title: "是否在首页展示",
    align: "center",
    dataIndex: "isShow",
    scopedSlots: { customRender: "isShow" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      type: '29',
      info:'',
      formInfo: '',
      columns,
      titleId: '',
      tableData: [],
      classList: [],
      loading:false,
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      autismClassificationId: ''
    };
  }, // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
      this.getList();
    },
    callback(e) {
      this.autismClassificationId = e
      this.pageNumber = 1
      this.getInfo();
      this.getList();
    },
    repair0(m) {
      return m < 10 ? '0' + m : m
    },
    createtime(times) {
      var time = new Date(times);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + this.repair0(m) + '-' + this.repair0(d) + ' ' + this.repair0(h) + ':' + this.repair0(mm) +
        ':' + this.repair0(s);
    },
    toDetail(operation, id) {
      this.$router.push("/admin/contentConfig/autismDetail?" + "operation=" + operation + "&id=" + id +'&classId=' + this.autismClassificationId);
    },
    editOk() {
      if(this.formInfo.length > 200){
        this.$message.warning('请限制介绍在200字以内!')
        return
      }
      this.edit = false;
       this.$ajax({
        url: "/hxclass-management/autism/classification/introduce/update",
        method: 'put',
        params: {
          name: this.formInfo,
          autismClassificationId: this.autismClassificationId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.info = this.formInfo
          this.$message.success(res.message)
        }
      });
    },
    editCancel(e) {
      this.edit = false;
    },
    editFilling() {
      this.formInfo = this.info
      this.edit = true;
    },
    onDelete(e) {
      this.$confirm({
        title: "确定删除吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/autism/del?autismId=" + e,
            method: "delete",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    getInfo(){
      this.$ajax({
        url: "/hxclass-management/autism/classification/introduce",
        params:{
          autismClassificationId: this.autismClassificationId 
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.info = res.data.name;
        }
      });
    },

    // 上下线
    onLine(e,type){
      // console.log(e,type)
      // return
      this.loading =true
      this.$ajax({
        url: "/hxclass-management/autism/update/type",
        method: "PUT",
        params: {
          autismId: e.autismId,
          status: type == 0 ? (e.state ? 0 : 1) : (e.type ? 0 : 1),
          type: type
        }
      }).then((res) => {
        this.loading =false
        if (res.code == 200 && res.success) {
          this.getList()
        }
      });
    },
    // 分类
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/autism/classification/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 100,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          if(res.data.records.length){
            this.autismClassificationId = res.data.records[0].autismClassificationId
            this.getInfo();
            this.getList();
          }
          this.classList = res.data.records;
        }
      });
    },
    getList(){
      this.loading =true
      this.$ajax({
        url: "/hxclass-management/autism/list",
        params: {
          autismClassificationId: this.autismClassificationId,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading =false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getData()
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .table-box{
    /deep/ .ant-tabs-bar{
      border: none;
      margin-bottom: 0;
    }
  }
  .introduce-box {
    margin-top: 24px;
    .introduce-title {
      font-size: 14px;
      color: #000;
      font-weight: 500;
    }
    .introduce-content {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      .introduce-left {
        textarea.ant-input {
          width: 1000px;
        }
        textarea.ant-input {
          height: 80px;
        }
      }
    }
  }
}
/deep/.ant-modal-header {
    padding: 16px 24px 0px;
    border-bottom: none;
  }
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  border-top: none;
  text-align: center;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
  textarea.ant-input {
          width: 448px;
        }
        textarea.ant-input {
          height: 120px;
        }
}
</style>
